
.logo {
  flex-shrink: 0;
  height: 2rem;
  width: 2rem;
  stroke-width: 2;
  border-radius: 9999px;
  padding: .25rem;
  border: solid 1px #ececec;
}

.user-logo {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  stroke-width: 2;
  border-radius: 9999px;
  /* padding: .25rem; */
  border: solid 1px #ececec;
  
}

.ai-name {
  font-weight: 600;
}

.ai-msg, .user-msg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: .75rem;
}

.ai-details, .user-details {
  /* display: flex;
  flex-direction: column; */
  position: relative;
  top: -1vh;
}

.App {
  margin: 2vh auto;
  /* text-align: center; */
}
.ai-container {
  text-align: start;
  width: 50%;
  border: 1px solid #ececec;
  padding: 2em;
  margin: 0 auto;
  /* background-color: yellow; */
}

.msg {
  padding: 0.2em 0.3em;
}

.download-button {
  display: inline-block;
  background-color: #4e4caf;;
  /* background-color: #4e4caf; */
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}


.actions {
  /* background-color: yellow; */
  width: 50%;
  margin: 0 auto;

  display: flex;
  flex: row;
  gap: 2em;
}

.avatar-container {
  margin-bottom: 20px;
}

.avatar-label {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.avatar-input {
  display: none;
  /* Hide the file input */
}

.avatar-preview {
  margin-top: 10px;
}

.avatar-image {
  max-width: 200px;
}

/* Navbar styles */
.navbar {
  /* background-color: #333; */
  /* color: #fff; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 7em;
}

/* Brand styles */
.navbar-brand {
  font-size: 24px;
  font-weight: 800;
}

/* Dropdown container styles */
.dropdown-container {
  margin-bottom: 20px;
}

/* Dropdown label styles */
.dropdown-label {
  font-size: 16px;
}

/* Dropdown select styles */
.dropdown-select {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  background-color: #fff;
  cursor: pointer;
}


/* Dropdown option styles */
.dropdown-select option {
  background-color: #fff;
  color: #333;
}


/* Cool Links container styles */
.cool-links-container {
  margin: 40px auto;
  /* background-color: #007bff; */
  width: 50%;
  margin-top: 10vh;

  border: 2px dashed hsla(0, 5%, 68.6%, .4);
  padding: 1em;
}

/* Cool Links title styles */
.cool-links-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Cool Links list styles */
.cool-links-list {
  list-style: none;
  padding: 0;
}

/* Cool Links list item styles */
.cool-links-list li {
  margin-bottom: 10px;
}

/* Cool Links anchor styles */
.cool-links-list a {
  color: #007bff;
  /* Link color */
  text-decoration: none;
  /* Remove underline */
  font-size: 18px;
}

/* Cool Links anchor hover styles */
.cool-links-list a:hover {
  text-decoration: underline;
  /* Underline on hover */
}

footer {
  position: relative;
  text-align: center;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  color: rgb(117, 113, 113);
  border-top: 2px dashed rgb(179, 171, 171, 0.4);
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-brand {
    margin-bottom: 10px;
  }

  .ai-container {
    width: 80%;
    margin: 0 auto;
    /* padding: 0; */
  }

  .actions {
    width: 90%;
    margin: 10px auto;
    gap: 6px;
    flex-wrap: wrap;
  }
  .avatar-label {
    width: 130px;
    margin: 0;
  }
  .download-button {
    width: 190px;
  }

  .navbar {
    padding: 20px;
  }

  .cool-links-container {
    width: 90%;
   }
}

