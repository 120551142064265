/* Hero section styles */
.hero {
  /* border: 1px solid #ececec; */
    /* background-color: #ececec; */
  padding: 50px 0;
  text-align: center;
}

/* Hero content styles */
.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Hero title styles */
.hero-title {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

/* Hero description styles */
.hero-description {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

/* Chat screenshots styles */
.chat-screenshots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-screenshots img {
  max-width: 100%;
  margin: 0 10px;
}